import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StateModule } from '@redux/state.module';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@utils/auth.interceptor';
import { AuthGuard } from '@utils/guards/auth.guard';
import { NoAuthGuard } from '@utils/guards/no-auth.guard';
import { AppService } from '@services/app.service';
import { NgxMaskModule } from 'ngx-mask';
import { AuthPrivateGuard } from '@utils/guards/auth-private.guard';
import { NoAuthPrivateGuard } from '@utils/guards/no-auth-private.guard';
import { AdminGuard } from '@utils/guards/admin-guard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { RouterModule } from '@angular/router';
import { AuthPrivateEmployeeGuard } from '@utils/guards/auth-private-employee.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    AgGridModule,
    StateModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxPermissionsModule.forRoot(),
    AngularSignaturePadModule,
    RouterModule
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    AuthPrivateGuard,
    NoAuthPrivateGuard,
    AdminGuard,
    AuthPrivateEmployeeGuard,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => async() => await appService.onLoad(),
      deps: [AppService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
