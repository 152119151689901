import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { ProcessState } from './_process/process-state';
import { AuthState } from './auth/auth-state';
import { HttpClientModule } from '@angular/common/http';
import { CategoriesState } from './categories/categories-state';
import { JobsState } from './jobs/jobs-state';
import { UserSkillsState } from './user/user-skills/user-skills-state';
import { UserPersonalInfoState } from '@redux/user/user-personal-info/user-personal-info-state';
import { UserLanguagesState } from './user/user-languages/user-languages-state';
import { UserAttachmentsState } from './user/user-attachments/user-attachments-state';
import { AttachmentsTypesState } from './attachments-types/attachments-types-state';
import { UserReferencesState } from './user/user-references/user-references-state';
import { UserWorksState } from './user/user-works/user-works-state';
import { JobsQuestionnariesState } from './jobs/jobs-questionnaries/jobs-questionnaries.state';
import { StatesState } from './states/states-state';
import { LicencesClassState } from './licences-class/licences-class-state';
import { LayoutState } from './layout/layout-state';
import { PermissionsTypesState } from './permissions-types/permissions-types-state';
import { PermissionsModulesState } from './permissions-modules/permissions-modules-state';
import { PermissionsState } from './permissions/permissions-state';
import { RolesState } from './roles/roles-state';
import { CompaniesState } from './companies/companies-state';
import { EmployeesState } from './employees/employees-state';
import { UsersState } from './users/users-state';
import { JobsTypesState } from './jobs-types/jobs-types.state';
import { QuestionsTypesState } from './questions-types/questions-types.state';
import { SalariesTypesState } from './salaries-types/salaries-types.state';
import { ApplicantStatusState } from './applicant-status/applicant-status.state';
import { UserApplicationsState } from './user/user-applications/user-applications-state';
import { DashboardState } from './dashboard/dashboard-state';
import { IncidentState } from './incident/incident-state';
import { EmployeePersonalState } from './employment-application/employee-personal-info/employee-personal-info-state';
import { EmployeEducationState } from './employment-application/employee-education/employee-eduction-state';
import { TypeEducationState } from './type_education/type-education-state';
import { EmployeWorkState } from './employment-application/employee-work/employee-work-state';
import { MaritalStatusState } from './marital-status/marital-status-state';
import { TypeCitizenState } from './type-citizen/type-citizen-state';
import { TypeIdentityState } from './type-identity/type-identity-state';
import { NationalityState } from './nacionality/nacionality-state';
import { CompaniesIncidentState } from './companies-incident/companies-incident-state';
import { CronJobsExecutionState } from './cron-jobs-execution/cron-jobs-execution-state';
import { ParameterPublicState } from './general-parameters/general-parameters.state';
import { MedicalReportCompaniesState } from './medical-report/medical-report-companies/medical-report-companies.state';
import { MedicalReportFormState } from './medical-report/medical-report-form/medical-report-form.state';
import { MedicalStatusReportState } from './medical-report/medical-report-status/medical-report-status.state';
import { MedicalReportAdminState } from './medical-report/medial-report-admin/medical-report-admin.state';
import { AuditsState } from './audits/audits.state';
import {
  MedicalReportConsultingState
} from '@redux/medical-report/medical-report-consulting/medical-report-consulting.state';

@NgModule({
  imports: [
    HttpClientModule,
    NgxsModule.forRoot(
      [
        ProcessState,
        AuthState,
        CategoriesState,
        JobsState,
        UserSkillsState,
        UserLanguagesState,
        UserPersonalInfoState,
        UserAttachmentsState,
        AttachmentsTypesState,
        UserReferencesState,
        UserWorksState,
        JobsQuestionnariesState,
        StatesState,
        LicencesClassState,
        LayoutState,
        PermissionsTypesState,
        PermissionsModulesState,
        PermissionsState,
        RolesState,
        CompaniesState,
        EmployeesState,
        UsersState,
        JobsTypesState,
        QuestionsTypesState,
        SalariesTypesState,
        ApplicantStatusState,
        UserApplicationsState,
        DashboardState,
        IncidentState,
        EmployeePersonalState,
        EmployeEducationState,
        TypeEducationState,
        EmployeWorkState,
        MaritalStatusState,
        TypeCitizenState,
        TypeIdentityState,
        NationalityState,
        CompaniesIncidentState,
        CronJobsExecutionState,
        CompaniesIncidentState,
        ParameterPublicState,
        MedicalReportCompaniesState,
        MedicalReportFormState,
        MedicalStatusReportState,
        MedicalStatusReportState,
        MedicalReportAdminState,
        AuditsState,
        MedicalReportConsultingState
      ],
      { developmentMode: !environment.production }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.dataToken', 'auth.scope', 'jobs.filters', 'jobs.search_filters', 'layout']
    })
  ]
})
export class StateModule {}
