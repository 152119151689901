import { environment } from '@environments/environment';

export const APP = {
  API_ROOT: `${environment.url_api}`,
  API: `${environment.url_api}/api`,
  API_PUBLIC: `${environment.url_api}/api/public`,
  API_PRIVATE: `${environment.url_api}/api/private`,
  API_AUTH: `${environment.url_api}/auth`,
  API_EXT: `${environment.url_api_nacinality}/v3.1`
};

export const ROUTES_AUTH = {
  VERIFY: `${APP.API}/verify`,
  LOGIN: `${APP.API_AUTH}/login`,
  SIGNUP: `${APP.API_AUTH}/register`,
  INFO: `${APP.API_AUTH}/info`,
  INFO_COMPANY: `${APP.API_PUBLIC}/info_company`,
  UNSUBSCRIBE: `${APP.API_PUBLIC}/unsubscribe`,
  SEND_EMAIL_VERIFY: `${APP.API_PUBLIC}/user/verify/email`,
  VERIFY_EMAIL: `${APP.API_PUBLIC}/verify/email`,
  AUTH_GOOGLE: `${APP.API_AUTH}/google`,
  AUTH_FACEBOOK: `${APP.API_AUTH}/facebook`,
  REQUEST_CHANGE_PASSWORD: `${APP.API_AUTH}/request_change_password`,
  CHANGE_PASSWORD_PUBLIC: `${APP.API_AUTH}/change_password`,
  LOGIN_EMAIL: `${APP.API_AUTH}/login_email`,
  TOKEN_LOGIN: `${APP.API_AUTH}/verify/email_login`
};

export const ROUTES_PUBLIC = {
  INFO_COMPANY: `${APP.API_PUBLIC}/info_company`,
  CATEGORIES: `${APP.API_PUBLIC}/categories`,
  JOBS: `${APP.API_PUBLIC}/jobs`,
  USER_SKILLS: `${APP.API_PUBLIC}/user/skills`,
  USER_LANGUAGES: `${APP.API_PUBLIC}/user/languages`,
  USERPERSONALINFO: `${APP.API_PUBLIC}/user/personal_info`,
  USER_ATTACHMENTS: `${APP.API_PUBLIC}/user/attachments`,
  ATTACHMENTS_TYPES: `${APP.API_PUBLIC}/types_attachments`,
  USER_REFERENCES: `${APP.API_PUBLIC}/user/references`,
  USER_WORKS: `${APP.API_PUBLIC}/user/works`,
  STATES: `${APP.API_PUBLIC}/states`,
  LICENSES_CLASS: `${APP.API_PUBLIC}/licenses_class`,
  APPLICATIONS: `${APP.API_PUBLIC}/user/applications`,
  EMPLOYE_PERSONAL_INTO_SSN: `${APP.API_PUBLIC}/user/find_by_ssn`,
  EDUCATION: `${APP.API_PUBLIC}/user/educations`,
  EMPLOYE_PERSONAL_INTO: `${APP.API}/employee_info`,
  TYPE_EDUCATION: `${APP.API}/type-educations`,
  EMPLOYEE_WORK: `${APP.API_PUBLIC}/user/works`,
  NATIONALITY: `${APP.API_EXT}/all`,
  INCIDENT: `${APP.API}/incident`,
  INCIDENT_DATA: `${APP.API}/incident/data`,
  COMPANIES_INCIDENT: `${APP.API}/incident/companies`,
  ATTACHMENTS_OFFER: `${APP.API_PUBLIC}/jobs/attachments-offer`,
  EMERGENCY_INCIDENT: `${APP.API}/incident/emergencies`,
  DAMAGES_INCIDENT: `${APP.API}/incident/damages`,
  PARAMETERS: `${APP.API_PUBLIC}/parameters`,
  MEDICAL_REPORT: `${APP.API_PUBLIC}/medical_reports`,
};


export const ROUTES_PRIVATE = {
  DEFAULT: `${APP.API_PRIVATE}`,
  CATEGORIES: `${APP.API_PRIVATE}/jobs_categories`,
  JOBS: `${APP.API_PRIVATE}/jobs_offers`,
  ATTACHMENTS_TYPES: `${APP.API_PRIVATE}/types_attachments`,
  PERMISSIONS_TYPES: `${APP.API_PRIVATE}/acl/permissions_types`,
  PERMISSIONS_MODULES: `${APP.API_PRIVATE}/acl/permissions_modules`,
  PERMISSIONS: `${APP.API_PRIVATE}/acl/permissions`,
  ROLES: `${APP.API_PRIVATE}/acl/roles`,
  COMPANIES: `${APP.API_PRIVATE}/companies`,
  EMPLOYEES: `${APP.API_PRIVATE}/employees`,
  EMPLOYEES_SEARCH: `${APP.API_PRIVATE}/employees_search`,
  USERS: `${APP.API_PRIVATE}/acl/users`,
  JOBS_TYPE: `${APP.API_PRIVATE}/jobs_types`,
  JOBS_TYPES: `${APP.API_PRIVATE}/jobs_types`,
  QUESTIONS_TYPES: `${APP.API_PRIVATE}/jobs_questions_types`,
  SALARIES_TYPES: `${APP.API_PRIVATE}/jobs_salaries_types`,
  APPLICANT_STATUS: `${APP.API_PRIVATE}/jobs_applicant_status`,
  JOBS_APPLICANTS: `${APP.API_PRIVATE}/jobs_applicants`,
  JOBS_APPLICANTS_ALL: `${APP.API_PRIVATE}/jobs_applicants_all`,
  JOBS_APPLICANTS_LIST: `${APP.API_PRIVATE}/get_applicants`,
  JOBS_APPLICANT_SELECTED: `${APP.API_PRIVATE}/get_applicant_selected`,
  DASHBOARD: `${APP.API_PRIVATE}/dashboard`,
  ADD_APPLICANTS: `${APP.API_PRIVATE}/newApplicant`,
  GENERATE_CODE_QR: `${APP.API_PRIVATE}/jobs_applicants_qr`,
  PENDING_JOB: `${APP.API_PRIVATE}/jobs/pending`,
  ASIGNATURE_APPLICANTS: `${APP.API_PRIVATE}/jobs_applicants_signature`,
  ASIGNATURE_APPLICANTS_READ: `${APP.API_PRIVATE}/jobs_applicants_signature_read`,
  MARITAL_STATUS: `${APP.API_PRIVATE}/marital_status`,
  TYPE_CITIZEN: `${APP.API_PRIVATE}/citizen_type`,
  TYPE_IDENTITY: `${APP.API_PRIVATE}/document_type_autorized`,
  UPLOAD_FILE: `${APP.API_PRIVATE}/upload`,
  PARAMETERS: `${APP.API_PRIVATE}/parameters`,
  PARAMETERS_VALUES: `${APP.API_PRIVATE}/parameter_values`,
  MEDICAL_REPORT: `${APP.API_PRIVATE}/medical_reports`,
  TERMED_EMPLOYEES: `${APP.API_PRIVATE}/termed_employees`,
  CRON_JOBS_EXECUTION: `${APP.API_PRIVATE}/cron_jobs_execution`,
};

export const SCOPES = {
  PUBLIC: `public`,
  PRIVATE: `private`,
  ENVIRONMENT_DEV: `${environment.production}`
};
