import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  JobsAddAction,
  JobsApplicantAllGetAction,
  JobsApplicantAsignatureAddAction,
  JobsApplicantAsignatureReadGetAction,
  JobsApplicantCodeQrGetAction,
  JobsApplicantEditAction,
  JobsApplicantEditFilterIdAction,
  JobsApplicantGetAction,
  JobsApplicantStatusOfferGetAction,
  JobsApplicantsAddAction,
  JobsApplicantsListAction,
  JobsApplicantsUpdateAction,
  JobsEditAction,
  JobsGetAction,
  JobsGetListJobOffersApplicantsAction,
  JobsGetPublicAction,
  JobsGetPublicApplicationInfoAction,
  JobsGetPublicDetailAction,
  JobsGetPublicSearchAction,
  JobsGetWitchApplicantsAction,
  JobsResetApplyAction,
  JobsSendPublicApplyAction,
  JobsSetFiltersAction,
  JobsUpdateAction,
  ToggleModalAction,
  JobsGetPendingGetAction
} from './jobs-actions';
import { Injectable } from '@angular/core';
import { JobsService } from '@services/jobs.service';
import { IJobs, IJobsSearch, IJobWitchApplicants, STATUS_JOB } from '@interfaces/jobs.interface';
import { IApplicants } from '@interfaces/admin-applicants.interface';
import { IApplicantInterested } from '@interfaces/apply-job-questionnaries.interface';
import { IPaginateData } from '@interfaces/api-paginate.interface';

export interface JobsStateModel {
  list: IJobs[];
  applicants: IJobWitchApplicants[];
  edit: IJobs;
  info_applicant: IApplicantInterested;
  filters: IJobsSearch;
  search_filters: IJobsSearch;
  list_applicantAll: IApplicants[];
  list_applicants: IPaginateData<IApplicants[]>;
  editApplicant: IApplicants;
  closeModal: boolean;
  rute_code_qr: string;
  signature: string;
  info_applicant_selected: IApplicants[] | undefined;
  codeStatusOffer: string;
  pending_jobs: number;
}

export const StateJobs = 'jobs';

@State<JobsStateModel>({
  name: StateJobs,
  defaults: {
    list: [],
    edit: {},
    applicants: [],
    info_applicant: null,
    filters: {},
    search_filters: null,
    list_applicantAll: [],
    list_applicants: null,
    editApplicant: null,
    closeModal: null,
    rute_code_qr: null,
    info_applicant_selected: undefined,
    signature: null,
    codeStatusOffer: null,
    pending_jobs: null
  }
})
@Injectable()
export class JobsState {
  constructor(private jobsService: JobsService) {}

  @Selector() static listJobs(state: JobsStateModel) {
    return state.list;
  }
  @Selector() static listJobsOpen(state: JobsStateModel) {
    return state.list.filter((i) => i?.status?.code === STATUS_JOB.OPEN);
  }
  @Selector() static applicants(state: JobsStateModel) {
    return state.applicants;
  }
  @Selector() static editJobs(state: JobsStateModel) {
    return state.edit;
  }
  @Selector() static infoApplicant(state: JobsStateModel) {
    return state.info_applicant;
  }
  @Selector() static filters(state: JobsStateModel) {
    return state.filters;
  }
  @Selector() static filtersSearch(state: JobsStateModel) {
    return state.search_filters;
  }

  @Selector() static listApplicantAll(state: JobsStateModel) {
    return state.list_applicantAll;
  }

  @Selector() static listApplicants(state: JobsStateModel) {
    return state.list_applicants;
  }

  @Selector() static editApplicant(state: JobsStateModel) {
    return state.editApplicant;
  }

  @Selector() static ruteCodeQr(state: JobsStateModel) {
    return state.rute_code_qr;
  }

  @Selector() static getInfoSelected(state: JobsStateModel) {
    return state.info_applicant_selected;
  }

  @Selector() static getSignature(state: JobsStateModel) {
    return state.signature;
  }

  @Selector() static statusCodeOffer(state: JobsStateModel) {
    return state.codeStatusOffer;
  }

  @Selector() static pendingJobs(state: JobsStateModel) {
    return state.pending_jobs;
  }
  @Action(JobsGetPublicAction)
  JobsGetPublic(ctx: StateContext<JobsStateModel>) {
    return this.jobsService.getPublicCurrents().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(JobsGetPublicSearchAction)
  JobsGetPublicSearch(ctx: StateContext<JobsStateModel>, { filters }: JobsGetPublicSearchAction) {
    return this.jobsService.getPublicFilterers(filters).pipe(
      tap(result => {
        ctx.patchState({
          list: result.data,
          filters: filters,
          search_filters: filters
        });
      })
    );
  }

  @Action(JobsSendPublicApplyAction)
  JobsSendPublicApply(ctx: StateContext<JobsStateModel>, { id }: JobsSendPublicApplyAction) {
    return this.jobsService.sendPublicApply(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(JobsGetPublicDetailAction)
  JobsGetPublicDetail(ctx: StateContext<JobsStateModel>, { code }: JobsGetPublicDetailAction) {
    return this.jobsService.getPublicDetail(code).pipe(
      tap(result => {
        ctx.patchState({
          edit: result.data
        });
      })
    );
  }

  @Action(JobsGetPublicApplicationInfoAction)
  JobsGetPublicApplicationInfo(ctx: StateContext<JobsStateModel>, { code }: JobsGetPublicApplicationInfoAction) {
    return this.jobsService.getApplicantStatusDetail(code).pipe(
      tap(result => {
        ctx.patchState({
          info_applicant: result.data
        });
      })
    );
  }

  @Action(JobsGetAction)
  JobsGet(ctx: StateContext<JobsStateModel>) {
    return this.jobsService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(JobsEditAction)
  JobsEdit(ctx: StateContext<JobsStateModel>, { data }: JobsEditAction) {
    return data?.id
      ? this.jobsService.show(data.id).pipe(
          tap(result => {
            ctx.patchState({
              edit: result.data
            });
          })
        )
      : ctx.patchState({
          edit: {}
        });
  }

  @Action(JobsAddAction)
  JobsAdd(ctx: StateContext<JobsStateModel>, { data }: JobsAddAction) {
    return this.jobsService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(JobsUpdateAction)
  JobsUpdate(ctx: StateContext<JobsStateModel>, { data }: JobsUpdateAction) {
    return this.jobsService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }

  @Action(JobsSetFiltersAction)
  JobsSetFilters(ctx: StateContext<JobsStateModel>, { filters }: JobsSetFiltersAction) {
    ctx.patchState({
      search_filters: filters
    });
  }

  @Action(JobsResetApplyAction)
  JobsResetApply(ctx: StateContext<JobsStateModel>) {
    ctx.patchState({
      edit: {},
      info_applicant: null
    });
  }

  @Action(JobsGetWitchApplicantsAction)
  JobsGetWitchApplicants(ctx: StateContext<JobsStateModel>) {
    return this.jobsService.getWithApplicants().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(JobsGetListJobOffersApplicantsAction)
  JobsGetListJobOffersApplicantsAction(
    ctx: StateContext<JobsStateModel>,
    { jobId }: JobsGetListJobOffersApplicantsAction
  ) {
    return this.jobsService.getListJobOffersApplicants(jobId).pipe(
      tap(result => {
        ctx.patchState({
          applicants: result.data
        });
      })
    );
  }

  @Action(JobsApplicantsUpdateAction)
  JobsApplicantsUpdate(ctx: StateContext<JobsStateModel>, { data }: JobsApplicantsUpdateAction) {
    return this.jobsService.updateJobsApplicants(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }

  @Action(JobsApplicantGetAction)
  JobsApplicantGet(ctx: StateContext<JobsStateModel>, { id }: JobsApplicantGetAction) {
    return this.jobsService.getJobApplicant(id).pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(JobsApplicantAllGetAction)
  JobsApplicantGetAll(ctx: StateContext<JobsStateModel>, { data }) {
    return this.jobsService.getApplicantsSearch(data).pipe(
      tap((result) => {
        ctx.patchState({
          list_applicantAll: result.data
        });
      })
    );
  }

  @Action(JobsApplicantsListAction)
  JobsApplicantsGet(ctx: StateContext<JobsStateModel>, { data, url, page }) {
    return this.jobsService.getApplicantsList(data, url, page).pipe(
      tap((result) => {
        const {data: paginateData}: { data: IPaginateData<IApplicants[]> } = result;
        ctx.patchState({
          list_applicants: paginateData
        });
      })
    );
  }

  @Action(JobsApplicantEditFilterIdAction)
  JobsApplicantGetId(ctx: StateContext<JobsStateModel>, { data }) {
    return this.jobsService.getApplicantsSearch(data).pipe(
      tap((result) => {
        ctx.patchState({
          info_applicant_selected: result.data
        });
      })
    );
  }

  @Action(JobsApplicantEditAction)
  JobsApplicantEdit(ctx: StateContext<JobsStateModel>, { data }: JobsApplicantEditAction) {
    if (data) {
      return this.jobsService.getApplicantSelected(data.id).pipe(
        tap((result) => {
          ctx.patchState({
            editApplicant: result.data
          });
        })
      );
    }
  }

  @Action(JobsApplicantsAddAction)
  JobsApplicantsAdd(ctx: StateContext<JobsStateModel>, { data }: JobsApplicantsAddAction) {
    return this.jobsService.postApplicantsAdd(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(JobsApplicantCodeQrGetAction)
  JobsApplicantsCodeQrGet(ctx: StateContext<JobsStateModel>, { id }: JobsApplicantCodeQrGetAction) {
    return this.jobsService.postApplicantsCodeQr(id).pipe(
      tap(result => {
        ctx.patchState({
          rute_code_qr: result.data
        });
      })
    );
  }

  @Action(JobsApplicantAsignatureAddAction)
  JobsApplicantAsignature(ctx: StateContext<JobsStateModel>, { data }: JobsApplicantAsignatureAddAction) {
    return this.jobsService.postApplicantsAsignature(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(JobsApplicantAsignatureReadGetAction)
  JobsApplicantAsignatureRead(ctx: StateContext<JobsStateModel>, { id }: JobsApplicantAsignatureReadGetAction) {
    return this.jobsService.postApplicantsAsignatureRead(id).pipe(
      tap(result => {
        ctx.patchState({
          signature: result.data
        });
      })
    );
  }

  @Action(JobsApplicantStatusOfferGetAction)
  JobsApplicantStatusOfferGet(ctx: StateContext<JobsStateModel>, { code }: JobsApplicantStatusOfferGetAction) {
    ctx.patchState({
      codeStatusOffer: code
    });
  }

  @Action(ToggleModalAction)
  CloseModal(ctx: StateContext<JobsStateModel>, { openModal }: ToggleModalAction) {
    ctx.patchState({
      closeModal: openModal
    });
  }
  @Action(JobsGetPendingGetAction)
  jobsGetPendingGet(ctx: StateContext<JobsStateModel>) {
    return this.jobsService.getPendingJobs().pipe(
      tap(result => {
        ctx.patchState({
          pending_jobs: Number(result.data)
        });
      })
    );
  }
}
