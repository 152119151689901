import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IQuestionsTypes } from '@interfaces/questions-types.interface';
import {
  QuestionsTypesAddAction,
  QuestionsTypesEditAction,
  QuestionsTypesGetAction,
  QuestionsTypesRemoveAction,
  QuestionsTypesSetShowDisabledAction,
  QuestionsTypesUpdateAction
} from './questions-types.actions';
import { QuestionsTypesService } from '@services/questions-types.service';

export interface QuestionsTypesStateModel {
  list: IQuestionsTypes[];
  show_disable: boolean;
  edit: IQuestionsTypes;
}

export const StateQuestionsTypes = 'Questions_types';

@State<QuestionsTypesStateModel>({
  name: StateQuestionsTypes,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class QuestionsTypesState {
  @Selector() static list(state: QuestionsTypesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: QuestionsTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: QuestionsTypesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: QuestionsTypesStateModel) {
    return state.edit;
  }

  constructor(private questionsTypesService: QuestionsTypesService) {}

  @Action(QuestionsTypesGetAction)
  QuestionsTypesGet(ctx: StateContext<QuestionsTypesStateModel>) {
    return this.questionsTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(QuestionsTypesEditAction)
  QuestionsTypesEdit(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(QuestionsTypesAddAction)
  QuestionsTypesAdd(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesAddAction) {
    return this.questionsTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesUpdateAction)
  QuestionsTypesUpdate(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesUpdateAction) {
    return this.questionsTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesRemoveAction)
  QuestionsTypesRemove(ctx: StateContext<QuestionsTypesStateModel>, { id }: QuestionsTypesRemoveAction) {
    return this.questionsTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesSetShowDisabledAction)
  QuestionsTypesSetShowDisabled(
    ctx: StateContext<QuestionsTypesStateModel>,
    { show }: QuestionsTypesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }
}
