import { IApplicants } from './admin-applicants.interface';
import { Status } from './apply-job-questionnaries.interface';
import { ICategories } from './categories.interface';
import { ICompanies } from './companies.interface';
import { IJobsTypes } from './jobs_types.interface';
import { ISalariesTypes } from './salaries-types.interface';

export interface FiltersJobs {
  job: string;
  address: string;
  category: string;
}

export interface IJobsOffers {
  id?: number;
  job?: IJobs;
  config?: IJobsOffersConfig;
  salaries?: IJobsOffersSalary[];
  tests?: IJobsOffersTest[];
}

export interface IJobs {
  id?: number;
  category_id?: number;
  category?: ICategories;
  company_id?: number;
  company?: ICompanies;
  type_id?: number;
  type?: IJobsTypes;
  code?: string;
  name?: string;
  vacancies?: number;
  address?: string;
  address_apt?: string;
  address_state?: string;
  address_county?: string;
  address_city?: string;
  address_zip?: string;
  address_lat?: string;
  address_lon?: string;
  date_begin?: string;
  date_end?: string;
  config?: IJobsOffersConfig;
  tests?: IJobsOffersTest[];
  active?: boolean;
  salaries?: IJobsOffersSalary[];
  created_at?: string;
  status_id?: number;
  status?: Status;
}

export interface IJobsOffersConfig {
  description?: string;
  benefits?: string;
  banner?: null;
  link_indeed?: null;
  email_receive_job1?: string;
  email_receive_job2?: string;
  created_at?: string;
  updated_at?: string;
  apply_works_min?: number;
  apply_skills_min?: number;
  apply_languages_min?: number;
  apply_references_min?: number;
  apply_attachments_required?: number[];
  contact_name?: null;
  contact_phone_1?: string;
  contact_phone_2?: string;
}

export interface IJobsOffersSalary {
  id?: number;
  type?: ISalariesTypes;
  type_id?: number;
  value_start?: number;
  value_end?: number;
}

export interface IJobsOffersTest {
  id?: number;
  name?: string;
  description?: string;
  apply_required?: boolean;
  active?: boolean;
  questions?: IJobsOffersQuestion[];
}

export interface IJobsOffersQuestion {
  id?: number;
  test_id?: number;
  type_id?: number;
  description?: string;
  answer_correct?: string;
  answers?: string;
  order?: number;
  type?: IJobsQuestionsType
}

export interface IJobsSearch {
  job?: number;
  categories?: string[];
  counties?: string[];
}

export interface IJobsTestQuestion {
  value?: number;
  description?: string;
}

export interface IJobsQuestionsType {
  id?: number;
  name?: string;
  code?: string;
  active: boolean;
}

export enum IJobsApplyTypes {
  IN_PROGRESS = 'progress',
  APPLY = 'apply'
}

export interface IJobWitchApplicants {
  id?: number;
  job_id?: number;
  job_name?: string;
  job_address?: string;
  job_status_id?: number;
  job_status_code?: string;
  job_status?: string;
  date_begin?: string;
  date_end?: string;
  category_id: number,
  category_name: string,
  apply?: number;
  approved?: number;
  progress?: number;
  rejected?: number;
  review?: number;
  stopped?: number;
  test?: number;
  status?: Status;
  applicant?: IApplicants;
  job?: IJobsOffers;
}

export enum STATUS_JOB {
  OPEN = 'open',
  PAUSE = 'pause',
  REMOVED = 'removed',
  CLOSED = 'closed'
}

export const ESTATUS_JOB_LIST = [
  {
    code: STATUS_JOB.OPEN,
    description: 'Open',
    id: 1
  },
  {
    code: STATUS_JOB.PAUSE,
    description: 'Pause',
    id: 2
  },
  {
    code: STATUS_JOB.CLOSED,
    description: 'Closed',
    id: 3
  },
  {
    code: STATUS_JOB.REMOVED,
    description: 'Removed',
    id: 4
  }
];

export interface IStatusJobs {
  code: string,
  description: string,
  id: number
}
