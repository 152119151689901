import { IQuestionsTypes } from '@interfaces/questions-types.interface';

export class QuestionsTypesGetAction {
  static readonly type = '[QuestionsTypes] List';
}

export class QuestionsTypesAddAction {
  static readonly type = '[QuestionsTypes] Add';
  constructor(public data: IQuestionsTypes) {}
}

export class QuestionsTypesEditAction {
  static readonly type = '[QuestionsTypes] Edit';
  constructor(public data: IQuestionsTypes) {}
}

export class QuestionsTypesUpdateAction {
  static readonly type = '[QuestionsTypes] Update';
  constructor(public data: IQuestionsTypes) {}
}

export class QuestionsTypesRemoveAction {
  static readonly type = '[QuestionsTypes] Remove';
  constructor(public id: number) {}
}

export class QuestionsTypesSetShowDisabledAction {
  static readonly type = '[QuestionsTypes] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessQuestionsTypes = {
  QuestionsTypesGetAction,
  QuestionsTypesAddAction,
  QuestionsTypesUpdateAction,
  QuestionsTypesRemoveAction,
  QuestionsTypesEditAction
};
